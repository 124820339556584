import React from 'react';
import { Form } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { useApiInstance } from 'api';
import qs from 'qs';
import Grid from 'components/Grid/Grid';
import FieldSet from 'components/FieldSet/FieldSet';
import { CheckRequired, CheckEmail, MapServerErrors } from 'components/FormAdapter/FormAdapter';
import Label from 'components/Form/Label/Label';
import Typography from 'components/Typography/Typography';
import Paper from 'components/Paper/Paper';
import Button from 'components/v2/Button/Button';
import { useDispatch } from 'react-redux';
import { InputFormField } from 'components/v2/FinalForm/Fields/InputFormField';
import { OUTSTAFF } from 'utils/base';
import { showNotifyNew } from '../../models/Notify';
import { nextRedirect } from '../../models/helpers';
import Link from '../Link/Link';

interface Values {
  email?: string;
  password?: string;
  rememberMe?: boolean;
}

interface Props {
  returnUrl?: string;
}

const AuthForm: React.FC<Props> = ({ returnUrl }) => {
  const intl = useIntl();
  const api = useApiInstance();
  const dispatch = useDispatch();
  const signIn = async (email: string | undefined, password: string | undefined) => {
    let result;
    try {
      result = await api.post('/company/login', { email, password });
    } catch (error) {
      result = error;
    }
    return result;
  };
  const onSubmit = async (values: Values) => {
    const result = await signIn(values.email, values.password);
    let errors;
    if (result.data && result.data.token) {
      nextRedirect(`/auth/token/${result.data.token}${returnUrl ? `?${qs.stringify({ return_url: returnUrl })}` : ''}`);
    } else if (result.response && result.response.status === 422) {
      errors = MapServerErrors(result.response.data.errors);
    } else {
      showNotifyNew(dispatch, {
        content: `Server error:
            ${result.statusCode ? result.statusCode : ''}
            ${result.message ? result.message : ''}`,
        type: 'error',
      });
    }
    return errors;
  };

  const formFields = [
    {
      title: intl.formatMessage({ id: 'app.auth.field.email.label' }),
      name: 'email',
      type: 'email',
      validate: CheckEmail,
    },
    {
      title: intl.formatMessage({ id: 'app.auth.field.password.label' }),
      name: 'password',
      type: 'password',
      validate: CheckRequired,
    },
  ];

  return (
    <Paper>
      <div className="geecko-authform-block">
        <Typography variant="h1" component="h1" spacing={3}>
          <FormattedMessage id="app.auth.title" />
        </Typography>
        <Typography variant="body3" bold component="div" spacing={5}>
          {`${intl.formatMessage({ id: 'app.auth.noaccount' })} `}
          <Link to={OUTSTAFF ? '/register/company' : '/register'}>
            {intl.formatMessage({ id: 'app.auth.register' })}
          </Link>
        </Typography>
        <Form
          onSubmit={onSubmit}
          initialValues={{ rememberMe: false }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              {formFields.map((field) => (
                <FieldSet spacing={5} key={field.name}>
                  <Label>{field.title}</Label>
                  <InputFormField name={field.name} type={field.type} validate={field.validate} />
                </FieldSet>
              ))}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Button size="lg" type="submit" isWide isLoading={submitting}>
                    {intl.formatMessage({ id: 'app.auth.enter' })}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Link to="/auth/password-recovery">
                    <Button variant="ghost" size="lg" color="secondary" isWide>
                      <FormattedMessage id="app.auth.forgotpassword" />
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
    </Paper>
  );
};

export default AuthForm;
