import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from 'store/ducks/auth/selectors';
import { redirect } from 'utils/redirect';
import { NextPage } from './_app';
import PageNext from '../src/components/Page/PageNext';
import Grid from '../src/components/Grid/Grid';
import AuthForm from '../src/components/AuthForm/AuthForm';
import '../src/components/AuthForm/AuthForm.css';

const AuthPage: NextPage = ({ query }) => {
  const intl = useIntl();
  const returnUrl = typeof query.return_url === 'string' && query.return_url ? query.return_url : undefined;
  const isAuthorized = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    if (isAuthorized) {
      redirect('/');
    }
  }, [isAuthorized]);

  return (
    <PageNext title={intl.formatMessage({ id: 'app.auth.title' })}>
      <div className="geecko-authform-container">
        <Grid
          container
          fullHeight
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <div style={{ maxWidth: 532, padding: 16, margin: '0 auto' }}>
              <AuthForm returnUrl={returnUrl} />
            </div>
          </Grid>
        </Grid>
      </div>
    </PageNext>
  );
};

AuthPage.accessGroups = 'public';

export default AuthPage;
