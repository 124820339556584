import React, { HTMLProps } from 'react';
import './FieldSet.css';

interface Props extends HTMLProps<HTMLFieldSetElement> {
  spacing?: number;
}

const FieldSet: React.FC<Props> = ({
  children, style = {}, spacing, ...other
}) => {
  const spaced = typeof spacing !== 'undefined' ? { marginBottom: spacing * 5 } : {};
  return (
    <fieldset
      className="geecko-fieldset"
      style={{ ...style, ...spaced }}
      {...other}
    >
      {children}
    </fieldset>
  );
};

export default FieldSet;
